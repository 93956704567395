import lang_fr from './lang/fr';
import library_fr from './packages/datakode/nuxt-datakode-utils/lang/fr';
import permissions_fr from './lang/permissions.fr';

export default defineI18nConfig(() => ({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    warnHtmlMessage: false,
    locale: 'fr',
    runtimeOnly: false,
    messages: {
        fr: {
            ...lang_fr,
            ...permissions_fr,
            ...library_fr
        }
    }
}))
