export default {
    actions: 'Actions',
    operators: {
        eq: "=",
        lt: "<",
        lte: "≤",
        gt: ">",
        gte: "≥",
    },
    credentials: {
        created_at: "Date de création",
        updated_at: "Date de modification",
        created_by: {
            name: "Créé par"
        },
        updated_by: {
            name: 'Modifié par',
        },
    },
    created_at: "Date de création",
    created_by: "Créé par",

    download: "Télécharger",
    colors: {
        success: "Succès",
        warning: "Avertissement",
        error: "Erreur",
        info: "Information",
    },
    documents: {
        index: "Documents",
        delete: {
            index: "Supprimer",
            title: "Suppression d'un document",
            text: "Le document {name} va être supprimé définitivement. <br/> Souhaitez-vous continuer ?"
        },
        toaster: {
            POST: {
                success: {
                    title: "Documents",
                    text: "Le document {name} a bien été créé",
                },
            },
            DELETE: {
                success: {
                    title: "Documents",
                    text: "Le document {name} a été supprimé",
                },
            },
        },
    },
    errors: {
        basic: "Une erreur est survenue",
        request: "La récupération des données a échouée. <br> Essayez de raffraichir la page ou de contacter un administrateur."
    },
    forms: {
        validations: {
            required: "Champ obligatoire",
            email: "Doit être un e-mail",
            integer: "Doit être un nombre entier",
            numeric: "Doit être une valeur numérique",
            maxLength: "Longueur maximum : {value}",
            minLength: "Longueur minimale : {value}",
        }
    },
    layers: {
        code: "Code",
        url: "URL",
        base_layer: "Fond de plan",
        default_layer: "Affiché par défaut",
    },
}
